import { NameValueDict } from '~/types/FormTypes';
import { UploadedFile } from '../types/Field';
import { ISelectOption } from './helper';
import { IClientInfo, IContactInfo, IProviderInfo } from './UserInfo';

// This tracks the status of the proposal as a whole
export enum ProposalStatus {
  Published = 'PUBLISHED',
  Connected = 'CONNECTED',
  InProgress = 'IN_PROGRESS',
  ProviderComplete = 'PROVIDER_COMPLETED',
  Verified = 'VERIFIED',
  Canceled = 'CANCELED',

  // Legacy
  // client side only (for now)
  Draft = 'DRAFT',
}

export const proposalStatusReadableString = {
  [ProposalStatus.Published]: 'Looking for Providers',
  [ProposalStatus.Connected]: 'Looking for Providers',
  [ProposalStatus.InProgress]: 'In progress',
  [ProposalStatus.ProviderComplete]: 'Provider marked the job as complete',
  [ProposalStatus.Verified]: 'Complete',
  [ProposalStatus.Canceled]: 'Canceled',
  [ProposalStatus.Draft]: 'Draft',
};

export enum ProposalProviderStatus {
  New = 'new',
  Connected = 'connected',
  Declined = 'declined',
  Canceled = 'canceled',
  Inactive = 'inactive',
  InProgress = 'in_progress',
  Completed = 'completed',
  Lost = 'lost',
  Unavailable = 'unavailable', // Catch-all for when other statuses are not applicable
}

export const proposalProviderStatusReadableString = {
  [ProposalProviderStatus.New]: 'Accepting Connections',
  [ProposalProviderStatus.Connected]: 'Connected',
  [ProposalProviderStatus.Declined]: 'Declined',
  [ProposalProviderStatus.Canceled]: 'Canceled',
  [ProposalProviderStatus.Inactive]: 'Inactive',
  [ProposalProviderStatus.InProgress]: 'In Progress',
  [ProposalProviderStatus.Completed]: 'Completed',
  [ProposalProviderStatus.Lost]: 'Accepted another bid',
  [ProposalProviderStatus.Unavailable]: 'Unavailable',
};

// This tracks the status of an individual provider
export enum ProposalConnectionStatus {
  Pending = 'PENDING',
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  Converted = 'CONVERTED',
  Lost = 'LOST',
}

export enum LeadProviderStatus {
  New = 'new',
  Connected = 'connected',
  Declined = 'declined',
  Unavailable = 'unavailable',
}

export enum JobProviderStatus {
  InProgress = 'in_progress',
  Completed = 'completed',
}

export enum JobRequestPersonalizedLeadsStatus {
  Pending = 'Pending',
  Accepted = 'Accepted',
  Declined = 'Declined',
}

export const proposalConnectionStatusReadableString = {
  [ProposalConnectionStatus.Pending]: 'Pending',
  [ProposalConnectionStatus.Accepted]: 'Accepted',
  [ProposalConnectionStatus.Declined]: 'Declined',
  [ProposalConnectionStatus.Converted]: 'Converted',
  [ProposalConnectionStatus.Lost]: 'Lost',
};

export enum ProposalOrigin {
  Personalized = 'PERSONALIZED',
  Provider = 'PROVIDER',
  Invite = 'INVITE',
}

export enum ProposalType {
  Invite = 'INVITE',
  Public = 'PUBLIC',
}

export enum ContactTimeOfDayOptions {
  Morning = 'morning_7am_12pm',
  Afternoon = 'afternoon_12pm_5pm',
  Evening = 'evening_5pm_8pm',
  Anytime = 'anytime_7am_8pm',
}

export const contactTimeOfDayReadableString = {
  [ContactTimeOfDayOptions.Morning]: 'Morning (7AM - 12PM)',
  [ContactTimeOfDayOptions.Afternoon]: 'Afternoon (12PM - 5PM)',
  [ContactTimeOfDayOptions.Evening]: 'Evening (5PM - 8PM)',
  [ContactTimeOfDayOptions.Anytime]: 'Anytime (7AM - 8PM)',
};

export interface IConnection {
  additionalDeclineInfo?: string;
  connectionStatus: ProposalConnectionStatus;
  bidId?: string;
  declineReasons?: string[];
  origin: ProposalOrigin;
  providerId: string;
  provider?: IProviderInfo;
  proposalId: string;
}

export enum RequesterType {
  Myself = 'MYSELF',
  Other = 'OTHER',
  Patient = 'PATIENT',
}

export const RequesterTypeLabel = {
  [RequesterType.Myself]: 'Myself',
  [RequesterType.Other]: 'Family or Caregiver',
  [RequesterType.Patient]: 'On Behalf of Patient',
};

export enum RequesterHealthRole {
  Planner = 'HOSPITAL_DISCHARGE_PLANNER',
  HomeHealthRep = 'HOME_HELP_REP',
}

export enum PreviousModifications {
  Yes = 'YES',
  No = 'NO',
  Unknown = 'UNKNOWN',
}

export enum PlannedPaymentOption {
  OutOfPocket = 'OUT_OF_POCKET',
  Insurance = 'INSURANCE',
  WorkersComp = 'WORKERS_COMP',
  Unsure = 'UNSURE',
}

const PlannedPaymentLabel = {
  [PlannedPaymentOption.OutOfPocket]: 'Out of Pocket',
  [PlannedPaymentOption.Insurance]: 'Private Insurance',
  [PlannedPaymentOption.WorkersComp]: "Worker's Compensation",
  [PlannedPaymentOption.Unsure]: "I don't know",
};

export function getPlannedPaymentLabel(s?: PlannedPaymentOption): string {
  if (!s) return '';
  return PlannedPaymentLabel[s];
}

export interface IProposal {
  [key: string]: any;

  id?: string;
  requester?: Partial<IClientInfo>;
  contact?: Partial<IContactInfo>;
  requesterId?: string; // For requests, can use this instead of a full requester object
  requesterType?: RequesterType;
  requesterHealthRole?: RequesterHealthRole;
  type: ProposalType;
  status?: ProposalStatus;
  connections?: IConnection[];
  providerConnection?: IConnection;
  config?: NameValueDict<string | boolean>;
  services?: ISelectOption[];
  streetAddress?: string;
  aptSuiteNumber?: string;
  city?: string;
  state?: string;
  zipCode?: number;
  projectStage?: ISelectOption;
  dateCreated?: Date | string;
  startDate?: string; // YYYY-mm-dd string
  rentOrOwn?: string;
  hasSharedWalls?: string;
  ageOfHome?: number;
  previousModifications?: PreviousModifications;
  isUsingInsuranceFunds?: boolean;
  plannedPaymentOptions?: PlannedPaymentOption[];
  isNeededBeforeMedicalDischarge?: boolean;
  isSupportingFunctionalDecline?: boolean;
  primaryContactId?: string;
  visuals?: UploadedFile[];
  supportingDocuments?: UploadedFile[];
  communicationMethod?: ISelectOption[];
  contactTimeOfDay?: ContactTimeOfDayOptions;
  additionalInformation?: string;
  savedByProviders?: string[];
  declineReasons?: string[];
  additionalDeclineInfo?: string;
}

export type ProposalFormKey = keyof IProposal;
