import {
  ContactTimeOfDayOptions,
  contactTimeOfDayReadableString,
  IProposal,
  PreviousModifications,
  RequesterHealthRole,
  RequesterType,
} from '../../model/Proposal';
import {
  Field,
  ImageField,
  ListField,
  MultiSelectField,
  RadioSelectField,
  SelectField,
  ServicesField,
  TextFieldType,
  TextLikeField,
  UploadedFile,
} from '../../types/Field';
import {
  DateFieldValidator,
  IntegerFieldValidator,
  ListFieldValidator,
  MultipleImageFieldValidator,
  MultiSelectFieldValidator,
  RadioSelectFieldValidator,
  RequiredSelectFieldValidator,
  TextFieldValidator,
} from './FieldValidators';
import { NameValueDict } from '../../types/FormTypes';
import { toJS } from 'mobx';

import { getYMDString, getUSStates } from '../helper';
import { ISelectOption } from '../../model/helper';
import { IServiceOption } from '../../model/UserInfo';
import { ModificationServiceName, ModificationServices } from '../ServiceConstants';

/**
 * serving the new form
 */
export class ProposalCreateFields {
  public proposal?: IProposal;

  public fields: NameValueDict<Field>;

  constructor(proposal?: IProposal) {
    this.proposal = toJS(proposal);

    this.fields = {
      requesterType: new SelectField(
        {
          id: 'requesterType',
          name: 'requesterType',
          label: 'I am completing this request for...',
          isInline: true,
          labelWidth: 8,
          placeholder: 'Select one',
          validators: [new RequiredSelectFieldValidator()],
          value: this.proposal ? this.proposal?.requesterType?.valueOf().toString() : '',
        },
        [
          {
            label: 'Myself',
            value: RequesterType.Myself,
          },
          {
            label: 'Friend/Family Member',
            value: RequesterType.Other,
          },
          {
            label: 'A Patient',
            value: RequesterType.Patient,
          },
        ] // options
      ),

      requesterHealthRole: new SelectField(
        {
          id: 'requesterHealthRole',
          name: 'requesterHealthRole',
          label: 'What is your role?',
          isInline: true,
          labelWidth: 8,
          placeholder: 'Select one',
          value: this.proposal ? this.proposal?.requesterHealthRole?.valueOf().toString() : '',
        },
        [
          {
            label: 'Hospital Discharge Planner',
            value: RequesterHealthRole.Planner,
          },
          {
            label: 'Home Health Agency Representative',
            value: RequesterHealthRole.HomeHealthRep,
          },
        ] // options
      ),

      streetAddress: new TextLikeField(
        {
          id: 'streetAddress',
          name: 'streetAddress',
          placeholder: 'Street Address and Unit',
          validators: [new TextFieldValidator('street address', { min: 2, max: 100 })],
          value: this.proposal?.streetAddress || '',
        },
        TextFieldType.Text
      ),

      aptSuiteNumber: new TextLikeField(
        {
          id: 'aptSuiteNumber',
          name: 'aptSuiteNumber',
          placeholder: 'Apartment or suite number',
          value: this.proposal?.aptSuiteNumber || '',
          label: 'Apartment or Suite',
        },
        TextFieldType.Text
      ),

      state: new SelectField(
        {
          id: 'state',
          name: 'state',
          label: 'Choose state',
          value: this.proposal?.state || '',
          validators: [new RequiredSelectFieldValidator('state')],
        },
        getUSStates() as ISelectOption[]
      ),

      city: new TextLikeField(
        {
          id: 'city',
          label: 'Enter city',
          name: 'city',
          placeholder: 'Enter city',
          validators: [new TextFieldValidator('city', { min: 2, max: 50 })],
          value: this.proposal?.city || '',
        },
        TextFieldType.Text
      ),

      zipCode: new TextLikeField(
        {
          id: 'zipCode',
          label: 'Enter ZIP code',
          name: 'zipCode',
          placeholder: 'Enter ZIP code',
          validators: [new IntegerFieldValidator('zip code')],
          value: this.proposal?.zipCode || '',
        },
        TextFieldType.Number
      ),

      communicationMethod: new MultiSelectField(
        {
          id: 'communicationMethod',
          name: 'communicationMethod',
          label: 'Enter preferred communication method',
          validators: [new MultiSelectFieldValidator('preferred communication methods', false)],
          value: this.proposal?.communicationMethod || [],
        },
        [
          { label: 'Phone - Call', value: 'phone_call' },
          { label: 'Phone - Text', value: 'phone_text' },
          { label: 'Email', value: 'email' },
          // { label: 'Video chat', value: 'video_chat' },
        ], // defaultOptions
        false // hasCustomOptions
      ),

      primaryContactId: new TextLikeField(
        {
          id: 'primaryContactId',
          name: 'primaryContactId',
          value: this.proposal?.primaryContactId || '',
        },
        TextFieldType.Hidden
      ),

      contactTimeOfDay: new SelectField(
        {
          id: 'contactTimeOfDay',
          name: 'contactTimeOfDay',
          label: 'What is the best time of day for the provider to contact you?',
          isInline: true,
          labelWidth: 8,
          placeholder: 'Select one',
          validators: [new RequiredSelectFieldValidator('contact time of day')],
          value: this.proposal ? this.proposal?.contactTimeOfDay?.valueOf().toString() : '',
        },
        [
          {
            label: contactTimeOfDayReadableString[ContactTimeOfDayOptions.Morning],
            value: ContactTimeOfDayOptions.Morning,
          },
          {
            label: contactTimeOfDayReadableString[ContactTimeOfDayOptions.Afternoon],
            value: ContactTimeOfDayOptions.Afternoon,
          },
          {
            label: contactTimeOfDayReadableString[ContactTimeOfDayOptions.Evening],
            value: ContactTimeOfDayOptions.Evening,
          },
          {
            label: contactTimeOfDayReadableString[ContactTimeOfDayOptions.Anytime],
            value: ContactTimeOfDayOptions.Anytime,
          },
        ] // options
      ),

      services: new ServicesField(
        {
          id: 'services',
          name: 'services',
          label: 'Services offered',
          value: this.proposal?.services as IServiceOption[],
        },
        ModificationServices, // defaultOptions
        true, // hasCustomOptions
        this.proposal?.services?.filter(
          (field) =>
            !ModificationServices.map((service) => service.value).includes(
              field.value as ModificationServiceName
            )
        ) || [], // customOptions
        'Add service' // customOptionPrompt
      ),

      startDate: new TextLikeField(
        {
          id: 'startDate',
          name: 'startDate',
          isInline: true,
          labelWidth: 8,
          placeholder: 'Enter start date',
          validators: [
            new DateFieldValidator(
              'start date',
              { min: getYMDString(new Date()) },
              'Start date cannot be in the past'
            ),
          ],
          value: this.proposal ? this.proposal.startDate : '',
        },
        TextFieldType.Date
      ),

      plannedPaymentOptions: new ListField({
        id: 'plannedPaymentOptions',
        name: 'plannedPaymentOptions',
        label: 'How will you pay for this modification?',
        isInline: true,
        labelWidth: 8,
        validators: [new ListFieldValidator('plannedPaymentOptions', true)],
        value: this.proposal?.plannedPaymentOptions || [],
      }),

      isNeededBeforeMedicalDischarge: new SelectField(
        {
          id: 'isNeededBeforeMedicalDischarge',
          name: 'isNeededBeforeMedicalDischarge',
          label: 'Do you want the modification done before discharge from a medical facility?',
          isInline: true,
          labelWidth: 8,
          placeholder: 'Select one',
          validators: [new RequiredSelectFieldValidator('field')],
          value: this.proposal
            ? this.proposal?.isNeededBeforeMedicalDischarge?.valueOf().toString()
            : '',
        },
        [
          { label: 'Yes', value: 'true' },
          { label: 'No', value: 'false' },
        ] // options
      ),

      isSupportingFunctionalDecline: new SelectField(
        {
          id: 'isSupportingFunctionalDecline',
          name: 'isSupportingFunctionalDecline',
          label:
            'Have you experienced a functional decline which this modification will support? (e.g. I was using a walker but now I need a wheelchair)',
          isInline: true,
          labelWidth: 8,
          placeholder: 'Select one',
          validators: [new RequiredSelectFieldValidator('field')],
          value: this.proposal
            ? this.proposal?.isSupportingFunctionalDecline?.valueOf().toString()
            : '',
        },
        [
          { label: 'Yes', value: 'true' },
          { label: 'No', value: 'false' },
        ] // options
      ),

      projectStage: new RadioSelectField(
        {
          id: 'projectStage',
          name: 'projectStage',
          label: 'What stage in your home accessibility search are you in?',
          validators: [new RadioSelectFieldValidator('project stage')],
          value: this.proposal?.projectStage || { label: '', value: '' },
        },
        [
          {
            label: 'Exploring Ideas',
            value: 'exploration',
          },
          {
            label: 'Budgeting',
            value: 'planning_and_budgeting',
          },
          {
            label: 'Ready to go',
            value: 'ready_to_hire',
          },
          {
            label: 'Project in progress',
            value: 'project_in_progress',
          },
        ] // options
      ),

      additionalInformation: new TextLikeField(
        {
          id: 'additionalInformation',
          name: 'additionalInformation',
          placeholder: 'Type additional info...',
          validators: [
            new TextFieldValidator('additional information', {
              min: 10,
              max: 10000,
            }),
          ],
          value: this.proposal?.additionalInformation || '',
        },
        TextFieldType.Textarea
      ),

      visuals: new ImageField(
        {
          id: 'visuals',
          name: 'visuals',
          validators: [
            new MultipleImageFieldValidator(
              'uploads' /** Entity names */,
              7 /** Maximum 7 files */,
              5 /** Maximum 5 Mb for images */,
              50 /** Maximum 50 Mb for videos */,
              false /** not required */
            ),
          ],
          value: this.proposal
            ? [
                ...(this.proposal.visuals?.map(
                  (visual) => new UploadedFile(visual.objectKey, visual.url, visual.description)
                ) || []),
              ]
            : undefined,
        },
        true, // multiple
        true, // allowsVideo
        'Upload File' // buttonText
      ),

      supportingDocuments: new ImageField(
        {
          id: 'supportingDocuments',
          name: 'supportingDocuments',
          validators: [
            new MultipleImageFieldValidator(
              'uploads' /** Entity names */,
              7 /** Maximum 7 files */,
              5 /** Maximum 5 Mb for images */,
              50 /** Maximum 50 Mb for videos */,
              false /** not required */
            ),
          ],
          value: this.proposal
            ? [
                ...(this.proposal.supportingDocuments?.map(
                  (visual) => new UploadedFile(visual.objectKey, visual.url, visual.description)
                ) || []),
              ]
            : undefined,
        },
        true, // multiple
        true, // allowsVideo
        'Upload File' // buttonText
      ),

      rentOrOwn: new SelectField(
        {
          id: 'rentOrOwn',
          name: 'rentOrOwn',
          label: 'Do you rent or own?',
          isInline: true,
          labelWidth: 8,
          placeholder: 'Select one',
          validators: [new RequiredSelectFieldValidator('field')],
          value: this.proposal ? this.proposal?.rentOrOwn?.valueOf().toString() : '',
        },
        [
          { label: 'Rent', value: 'rent' },
          { label: 'Own', value: 'own' },
          { label: 'Not Known', value: 'not known' },
        ] // options
      ),

      hasSharedWalls: new SelectField(
        {
          id: 'hasSharedWalls',
          name: 'hasSharedWalls',
          isInline: true,
          labelWidth: 8,
          placeholder: 'Select one',
          validators: [new RequiredSelectFieldValidator('field')],
          value: this.proposal ? this.proposal.hasSharedWalls?.valueOf().toString() : '',
        },
        [
          { label: 'Yes', value: 'YES' },
          { label: 'No', value: 'NO' },
          { label: 'Not Known', value: 'UNKNOWN' },
        ] // options
      ),

      ageOfHome: new TextLikeField(
        {
          id: 'ageOfHome',
          name: 'ageOfHome',
          placeholder: 'Enter years',
          isInline: true,
          labelWidth: 8,
          validators: [new IntegerFieldValidator('age of home', { min: 0, max: 5000 }, false)],
          value: this.proposal ? this.proposal.ageOfHome : '',
        },
        TextFieldType.Number
      ),

      previousModifications: new SelectField(
        {
          id: 'previousModifications',
          name: 'previousModifications',
          label: 'Has your residence had accessible home modifications completed in the past?',
          isInline: true,
          labelWidth: 8,
          placeholder: 'Select one',
          validators: [new RequiredSelectFieldValidator('field')],
          value: this.proposal ? this.proposal.previousModifications?.valueOf().toString() : '',
        },
        [
          { label: 'Yes', value: PreviousModifications.Yes },
          { label: 'No', value: PreviousModifications.No },
          { label: "I Don't Know", value: PreviousModifications.Unknown },
        ] // options
      ),
    };
  }

  getFields(names: string[]): NameValueDict<Field> {
    const ret: NameValueDict<Field> = {};
    Object.values(this.fields).forEach((f) => {
      if (f) {
        if (names.indexOf(f.name) > -1) {
          ret[f.name] = f;
        }
      }
    });
    return ret;
  }
}
